export default {
    colors: {
      text: '#333333',
      'text-light': '#c4cad4',
      background: '#ffffff',
      // primary: '#a3d9ff',
      links: '#31ce79',
      gray: '#718096',
      'light-gray': '#f6f6f6',
      accent: '#a3d9ff', // BrainNoteContainer.js uses color 'accent' for background on NoteWrapper
      // https://coolors.co/9684a1-31ce79-333333-718096-a3d9ff
    },
    breakpoints: ['640px', '768px', '1024px', '1280px'],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    borders: [0, 1, 2, 3, 4],
    radii: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    fonts: {
      body:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      heading: 'inherit',
      monospace: 'Menlo, monospace',
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
    fontWeights: {
      body: 400,
      heading: 700,
      bold: 700,
    },
    lineHeights: {
      body: 1.5,
      heading: 1.125,
    },
    links: {
      internal: {
        color: 'links',
        px: '2px',
        mx: '-2px',
        borderRadius: 1,
        // ':focus': {
        //   bg: 'accent',
        // },
      },
    },
    styles: {
      root: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'body',
      },
      h1: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 5,
      },
      h2: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 4,
      },
      h3: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 3,
      },
      h4: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 2,
      },
      h5: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 1,
      },
      h6: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 0,
      },
      p: {
        color: 'text',
      },
      a: {
        color: 'links',
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'underline',
        },
      },
      pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
          color: 'inherit',
        },
      },
      code: {
        fontFamily: 'monospace',
        fontSize: 'inherit',
      },
      table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0,
      },
      th: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
      },
      td: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
      },
      img: {
        maxWidth: '100%',
      },
    },
  };